import React, { Fragment } from 'react';
import FormField from 'vendor/Form/FormField';
import PrimaryButton from '@bonlineza/luxity-lib/PrimaryButton';
import axios from 'helpers/axios';
import api from 'constants/api';
import responseMessages from 'functions/responseMessages';

class PasswordForm extends React.Component {
  state = {
    password: null,
    password_confirmation: null,
    errorMessages: {},
    isApiBusy: false,
  };

  controlRef = (name, value) => {
    const { errorMessages } = this.state;
    const newErrorMessages = { ...errorMessages };
    delete newErrorMessages[name];
    this.setState({ [name]: value, errorMessages: newErrorMessages });
  };

  onSubmit = () => {
    const { password, password_confirmation, verification_code } = this.state;
    if (
      verification_code.length &&
      password.length &&
      password === password_confirmation
    ) {
      this.setState({ isApiBusy: true });
      axios
        .post(api.user.FORGOT_PASSWORD_VERIFY, {
          verification_code,
          password,
          password_confirmation,
        })
        .then(() => {
          this.setState({ isApiBusy: false });
          this.props.actionCallback('password_updated')();
        })
        .catch((e) => {
          this.setState({ isApiBusy: false });
          this.handleError(e.status, e.data);
        });
    } else {
      this.setState((prevState) => ({
        ...prevState,
        errorMessages: {
          ...prevState.errorMessages,
          password: 'Please enter valid Password and confirmation',
        },
      }));
    }
  };

  handleError = (code, errorData = {}) => {
    if (code === 422) {
      this.setState({ errorMessages: responseMessages(errorData) });
    } else {
      this.props.actionCallback('generic_error')();
    }
  };

  render = () => {
    const { onCancel } = this.props;
    const { isApiBusy } = this.state;
    return (
      <Fragment>
        <FormField
          label="OTP*"
          name="verification_code"
          type="text"
          itemClass="form__item--horizontal"
          labelClass="form__item__label"
          controlRef={this.controlRef}
          errorFields={this.state.errorMessages}
        />
        <FormField
          label="New Password*"
          name="password"
          type="password"
          itemClass="form__item--horizontal"
          labelClass="form__item__label"
          controlRef={this.controlRef}
          errorFields={this.state.errorMessages}
        />
        <FormField
          label="New Password Confirmation*"
          name="password_confirmation"
          type="password"
          itemClass="form__item--horizontal"
          labelClass="form__item__label"
          controlRef={this.controlRef}
          errorFields={this.state.errorMessages}
        />
        <div className="fl-right">
          <div className="fl-right__item">
            <PrimaryButton type="button" onClick={onCancel}>
              <div className="flex gap-4 items-center">
                Cancel
                <img
                  src="/assets/images/delete.png"
                  alt="cancel"
                  width={15}
                  height={15}
                />
              </div>
            </PrimaryButton>
          </div>
          <div className="fl-right__item">
            <PrimaryButton
              disabled={isApiBusy}
              type="button"
              onClick={this.onSubmit}>
              <div className="flex gap-4 items-center">
                {isApiBusy ? 'Submitting...' : 'Change password'}
                <img
                  src="/assets/images/reset-password.png"
                  alt="reset password"
                  width={15}
                  height={15}
                />
              </div>
            </PrimaryButton>
          </div>
        </div>
      </Fragment>
    );
  };
}

export default PasswordForm;
