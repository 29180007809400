import React, { Fragment, useState, useRef } from 'react';
import GAButton from 'components/GAButton';
import api from 'constants/api';
import axios from 'helpers/axios';
import LuxityLoader from 'components/LuxityLoader';
import checkMinimumPaymentAmount from 'functions/checkMinimumPaymentAmount';

export default function Index({
  sale_id,
  sale_outstanding_amount,
  sale_total_amount,
}) {
  const [paymentAmount, setPaymentAmount] = useState();
  const [validationMsg, setValidationMsg] = useState();
  const [paygate, setPaygate] = useState();
  const formRef = useRef();
  const inputAmountRef = useRef();
  const [errExists, setErrExists] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const submitHiddenForm = () => {
    formRef.current.submit();
  };
  const handleValidation = () => {
    if (inputAmountRef.current.validity.badInput) {
      setErrExists(true);
      setValidationMsg(`This field only accepts numbers`);
      return false;
    }
    if (!paymentAmount || parseInt(paymentAmount, 10) === 0) {
      setErrExists(true);
      setValidationMsg(`Enter payment amount`);
      return false;
    }
    if (parseInt(paymentAmount, 10) > parseInt(sale_outstanding_amount, 10)) {
      setErrExists(true);
      setValidationMsg(
        `The amount you are paying is more than the outstanding balance`,
      );
      return false;
    }

    if (
      checkMinimumPaymentAmount(
        sale_outstanding_amount,
        sale_total_amount,
        paymentAmount,
      )
    ) {
      setErrExists(true);
      setValidationMsg(
        `The payment amount must be at least 25% of the order total.`,
      );
      return false;
    }
    if (paymentAmount < 0) {
      setErrExists(true);
      setValidationMsg(`Amount needs to be bigger than 0`);
      return false;
    }
    return true;
  };
  const handleMakePaymentClick = () => {
    const isValid = handleValidation();
    if (isValid) {
      setIsLoading(true);
      axios
        .post(api.data.PAY_OUTSTANDING_AMOUNT, {
          amount: paymentAmount,
          sale_id,
        })
        .then((res) => {
          setPaygate(res.data);
          submitHiddenForm();
        })
        .catch((err) => {
          setErrExists(true);
          setValidationMsg(err.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const makePaymentInputDiv = () => {
    return (
      <div className="u-text--right make-payment-container">
        <input
          className="make-payment-input"
          ref={inputAmountRef}
          name="amount"
          onChange={(e) => {
            e.preventDefault();
            setPaymentAmount(e.target.value);
          }}
          type="number"
          placeholder="R"
        />
      </div>
    );
  };

  const makePaymentButton = () => {
    return isLoading ? (
      <LuxityLoader />
    ) : (
      <GAButton
        className="btn--luxity make-payment-button"
        label="Make Payment"
        onClick={(e) => {
          e.preventDefault();
          handleMakePaymentClick();
        }}>
        PROCEED TO CREDIT CARD PAYMENT
      </GAButton>
    );
  };

  const errorMessageDiv = () => {
    return (
      <span className="payment-error-msg">
        {errExists ? validationMsg : null}
      </span>
    );
  };

  return (
    <Fragment>
      <div className="accordion-container">
        <div className="desktop-payment-container">
          <div className="payment__error">{errorMessageDiv()}</div>
          <div className="payment__content">
            <span className="text-and-input">
              <b>Enter Amount:</b>
              {makePaymentInputDiv()}
            </span>
            <span className="payment-button">{makePaymentButton()}</span>
          </div>
        </div>

        <div className="mobile-payment-container">
          <div className="payment__error">{errorMessageDiv()}</div>
          <div className="payment__content">
            <span>
              <b>Enter Payment Amount:</b>
            </span>
            <span>{makePaymentInputDiv()}</span>
            <div>{makePaymentButton()}</div>
          </div>
        </div>
      </div>

      {paygate && (
        <form
          id="hidden_form"
          ref={formRef}
          action={paygate.url || ''}
          method="POST">
          <input
            type="hidden"
            name="PAY_REQUEST_ID"
            value={paygate.PAY_REQUEST_ID || ''}
          />
          <input type="hidden" name="CHECKSUM" value={paygate.CHECKSUM || ''} />
          <input type="submit" className="u-hidden" value="SUBMIT" />
        </form>
      )}
    </Fragment>
  );
}
