import React, { useState } from 'react';
import AxiosPageReadyWrapper from 'components/AxiosPageReadyWrapper';
import api from 'constants/api';
import { submissionStatuses } from 'constants/submissionStatuses';
import { getUserFormSubmissionsQuery } from './query';
import SubmissionDetails from './components/SubmissionDetails';

const Submissions = ({ updateSubmissions }) => {
  const [submissions, setSubmissions] = useState([]);

  const getSubmissions = (data) => {
    const {
      data: {
        data: { user_form_submissions = { data: [] } },
      },
    } = data;

    setSubmissions(user_form_submissions.data);
    updateSubmissions(user_form_submissions.data);
  };

  return (
    <AxiosPageReadyWrapper
      method="post"
      url={api.graphql()}
      postBody={{
        query: getUserFormSubmissionsQuery(20, true, [submissionStatuses.NEW]),
      }}
      onData={getSubmissions}>
      {submissions.length > 0 && (
        <SubmissionDetails submissions={submissions} />
      )}
    </AxiosPageReadyWrapper>
  );
};

export default Submissions;
